import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "kaufOderMieteGrundsatzfrage";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Nicht jeder hat schon in jungen Jahren mehrere Tausend Euro gespart, geerbt oder im Lotto gewonnen.
                    Eigenkapital ermöglicht dir aber nicht nur Flexibilität und bessere Konditionen, es verringert
                    natürlich auch die Höhe deines Kredits. Banken sind oft bereit, dir auch ohne großes Eigenkapital,
                    einen Kredit zu geben. Doch die hohen Nebenkosten und der Aufwand, die mit einem Kredit anfallen,
                    können abschreckend sein.
                </p>

                <p>
                    Solltest du wirklich eine Immobilie Kaufen und dafür einen Kredit aufnehmen? Wenn du keine Angst
                    davor hast, dich zu verschulden und an etwas gebunden zu sein, unbedingt! Und vielleicht lässt sich
                    diese Angst auch nehmen.
                </p>

                <p>
                    Im Folgenden geben wir dir einen kurzen Überblick über die jeweiligen Vor- und Nachteile von Kauf
                    und Miete. Zunächst zum Mieten, das folgende Vorteile hat:
                </p>
                <ul>
                    <li>
                        In zahlreichen Fällen ist der Mietzins auf lange Sicht etwas niedriger als die monatliche
                        Kreditrate inkl. der Instandhaltungskosten
                    </li>

                    <br />

                    <li>
                        Flexibilität: Solltest du wegen Jobwechsel, Beziehungsaus oder anderer Ursachen deinen Wohnort
                        wechseln müssen, so ist das im Falle eines Mietverhältnis in relativ kurzer Frist möglich
                    </li>

                    <br />

                    <li>
                        Ersparnis von Kosten- und Zeit im Schadenfall: Geht in deiner Wohnung ohne dein Verschulden
                        etwas zu Bruch, so bist nicht du selbst, sondern die Vermieter:in bzw. die Hausverhaltung ist
                        für die Reparatur zuständig
                    </li>

                    <br />

                    <li>
                        Bei ungünstiger Entwicklung der Wohngegend kommt es nicht zum Wertverlust, der sich negativ auf
                        einen potenziellen Wiederverkauf einer Eigentumswohnung auswirken würde
                    </li>
                </ul>

                <p>Die Nachteile des Mietens liegen besonders hier:</p>
                <ul>
                    <li>Die Zahlungen der Miete fließen nicht in dein eigenes Vermögen</li>

                    <br />

                    <li>
                        Vor Mieterhöhungen bist du nicht geschützt, sondern den Launen des Marktes ausgeliefert: Wird
                        dein Wohnort plötzlich beliebt, so kann deine Miete drastisch steigen, sodass du dir im
                        schlimmsten Fall deine Wohnung nicht mehr leisten kannst
                    </li>

                    <br />

                    <li>
                        Bei der Umgestaltung der Wohnung sind dir durch den Mietvertrag Grenzen gesetzt. Gerade wenn du
                        in der Zukunft möglicherweise eine Familie gründen möchtest oder die Anschaffung von Haustieren
                        andenkst, kann das ein erheblicher Nachteil sein
                    </li>

                    <br />

                    <li>
                        Bei ungünstiger Entwicklung der Wohngegend kommt es nicht zum Wertverlust, der sich negativ auf
                        einen potenziellen Wiederverkauf einer Eigentumswohnung auswirken würde
                    </li>
                </ul>

                <p>Hieraus ergeben sich zugleich einige Vorteile des Immobilienkaufs:</p>
                <ul>
                    <li>Die Rückzahlung des Kredits kommt dem Aufbau des eigenen Vermögens zugute</li>

                    <br />

                    <li>
                        In den eigenen vier Wänden sind deiner Kreativität keine Grenzen gesetzt, du kannst sie nach
                        Belieben umgestalten (sofern du die lokalen RIchtlinien beachtest)
                    </li>

                    <br />

                    <li>
                        Der Kauf bringt steuerliche Vorteile: der Immoblienerwerb birgt geringere Steuern als andere
                        Renditen
                    </li>

                    <br />

                    <li>
                        Durch die monatliche Ratenzahlung wirst du sozusagen automatisch zum Sparen angehalten; das
                        führt zu größerer Disziplin in Geldfragen, was sich besonders günstig auf deine
                        Vermögenssituation nach der Rückzahlung des Kredits auswirkt
                    </li>
                </ul>

                <p>Doch auch auch der Immobilienerwerb birgt Nachteile, besonders folgende:</p>
                <ul>
                    {/*Commented out this cons, as they do not make sense to me*/}
                    {/* TODO: A break even feature / analysis would make sense */}
                    {/*<li>*/}
                    {/*  Geringe Flexibilität: Ein attraktives Jobangebot in einer anderen Stadt lässt sich als Eigentümer:in kaum kurzfristig wahrnehmen*/}
                    {/*</li>*/}

                    {/*<br/>*/}

                    {/*<li>*/}
                    {/*  Der laufende Kredit kann gerade in öknomischen oder sozialen  Ausnahmesituationen zur Belastung werden*/}
                    {/*</li>*/}

                    <br />

                    <li>
                        Die Instandhaltungskosten musst du selbst bezahlen. Gerade wenn nicht vorhersehbare Schäden
                        auftreten, siehst du dich unvermutet vor ggf. hohe Reparaturkosten gestellt
                    </li>

                    <br />

                    <li>
                        ‘Klumpenrisiko’: Da du für den Kauf einer Immmobilie vermutlich den Großteil deines Vermögens
                        aufwendest, steigt auch das Verlustrisiko bei ausbleibender Wertsteigerung - schließlich hast du
                        dein Kapital ja größtenteils in eine Sache investiert
                    </li>
                </ul>

                <p>
                    Das Geld, welches für deine monatliche Miete draufgeht, könnte auch in deine eigene Immobilie
                    fließen. Zahlen musst du sowieso, für jedes Zuhause. Die Mietpreise in den Städten steigen weiter
                    und Investition in Immobilien ist immer noch eine der sichersten Arten, sein Geld anzulegen. Oft
                    können die monatliche Kreditrate und Betriebskosten sogar niedriger ausfallen, als eine
                    durchschnittliche Monatsmiete für dein Zuhause. Und am Ende bleibt dir immer noch die Wahl, deine
                    Immobilie weiterzuvermieten oder wieder zu verkaufen.
                </p>

                <p>
                    Du musst auch nicht reich sein, um einen Immobilienkredit zu bekommen. Um trotz fehlendem
                    Eigenkapital einen Kredit zu bekommen, musst du der Bank nur beweisen können, dass du ein
                    regelmäßiges, sicheres Einkommen hast und in der Lage sein wirst, deinen Kredit auch in Krisenzeiten
                    zurückzuzahlen. Wenn du dich ausreichend informierst, kannst du den passenden Kredit für genau deine
                    Situation finden und später auch immer wieder Umschulden.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
